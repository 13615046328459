/*

Customize variables:

- Check the ../../arDominionB5Plugin/scss/variables.scss file.
- Check the B5 documentation (https://getbootstrap.com/docs/5.0/customize/sass/).
- Import B5 functions, variables and mixins if needed.

*/

$red: #D6001C;
@use '../../arDominionB5Plugin/scss/main' with (
  $primary: $red,
  $link-color: $red
);

/*

Extend:

- Add extra SCSS and CSS imports.
- Add custom styles directly.

*/

@import "https://kit.fontawesome.com/16de87596e.css";
@import "https://libapps-ca.s3.amazonaws.com/sites/546/include/styles.css";
@import "ucalgary.scss";