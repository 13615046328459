/*------------------------------------*\
****************************************
  
  UCALGARY-OVERWRITE-STYLES

  * use this css file to add ucalgary
  * overwrite css only. all ucalgary
  * custom styles are in this file.

  developed by the dev team, libraries 
  and cultural resources, university of
  calgary 2023.

****************************************
\*------------------------------------*/


/*------------------------------------*\
  #GENERAL-STYLES
\*------------------------------------*/

/**
 * headings. 
 */

h1, h2, h3, h4, h5, h6 {
  margin-top: inherit;
}

/**
 * links. 
 */

a:hover {
  text-decoration: none;
}

/**
 * lists. 
 */

ol, 
ul {
  padding-left: initial;
}

ul {
  margin-left: initial;
}





/*------------------------------------*\
  #HEADER-STYLES
\*------------------------------------*/

.c-header {
  position: relative;
}

.c-header__toolbox-button.js-toolbox__button--open::after {
  padding: 0px;
}

@media (min-width: 1200px) {
  #top-bar .container-fluid {
    max-width: 1170px;
  }
}

.text-primary {
  color: #9C0534 !important;
}

.navbar-toggler {
  font-size: 1rem;
}





/*------------------------------------*\
  #CONTAINER-STYLES
\*------------------------------------*/

/**
 * bootstrap container overwrites. 
 */

@media (min-width: 1200px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1170px;
  }
}





/*------------------------------------*\
  #BUTTON-STYLES
\*------------------------------------*/

/**
 * atom button overwrites. 
 */

 .atom-btn-secondary:hover,
 .atom-btn-secondary:focus {
   background-color: #D6001C;
   border-color: #D6001C;
 }
 
 .atom-btn-outline-light:visited {
   color: #FFF;
 }
 
 .atom-btn-outline-light:hover {
   color: #FFF;
   background-color: #D6001C;
   border-color: #D6001C;
 }
 
 .atom-btn-white:hover i,
 .atom-btn-white:focus i,
 .navbar-dark .navbar-nav .nav-link:hover,
 .navbar-dark .navbar-nav .nav-link:focus,
 .page-link {
   color: #D6001C;
 }
 
 .page-link:hover {
   color: #9C0534;
 }
 
 .form-check-input:checked {
   background-color: #D6001C;
   border-color: #D6001C;
 }
 
 .navbar-nav .fa-2x {
   font-size: 1.2rem;
 }
 
 .atom-btn-white.active-primary.active {
   background-color: #FF671F;
   border-color: #FF671F;
 }




/*------------------------------------*\
  #IMAGE-STYLES
\*------------------------------------*/

/**
 * ucalgary img overwrite. 
 */

img {
  width: inherit;
  height: inherit;
}
